import React, { Component } from 'react';
import { Layout } from './components/Layout';
import RegoForm from './components/RegoForm';
import BrowserUnsupported from './components/BrowserUnsupported';

import './index.css'

function isIE() {
    //return true;
    // IE 10 and IE 11
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

const App = () => {
    const displayName = App.name;
    
        return (
            <Layout>
                {
                    isIE()
                    ? <BrowserUnsupported/>
                    : <RegoForm/>
                }
                
            </Layout>)
}

export default App;
