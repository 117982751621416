
const licenceClasses = [
    {value: 'LICC1L', label: 'Class 1 - Car Learners'},
    {value: 'LICC1R', label: 'Class 1 - Car Restricted'},
    {value: 'LICC1', label: 'Class 1 - Car'},
    {value: 'LICC2', label: 'Class 2 - Medium Rigid'},
    {value: 'LICC3', label: 'Class 3 - Medium Combination'},
    {value: 'LICC4', label: 'Class 4 - Heavy Rigid'},
    {value: 'LICC5', label: 'Class 5 - Heavy Combination'},
    {value: 'LICC6', label: 'Class 6 - Motorcycle'}
];

const licenceEndorsements = [
    {value: 'LICENDD', label: 'Endorsement - D - Dangerous Goods'},
    {value: 'LICENDFC1', label: 'Endorsement - F - Forkhoist Class 1'},
    {value: 'LICENDFC2', label: 'Endorsement - F - Forkhoist Class 2'}
];

const licenseSkillsExperience = [
    {value: 'LICSE1', label: 'Skill - Forkhoist - Counterbalance'},
    {value: 'LICSE2', label: 'Skill - Forkhoist - Stock Picker'},
    {value: 'LICSE3', label: 'Skill - Forkhoist - Reach Truck'},
    {value: 'LICSE4', label: 'Skill - Forkhoist - Container Devanning'},
    {value: 'LICSE5', label: 'Skill - Forkhoist - Pallet Truck'}
];

const administration = [
    {value: 'POSADMIN', label: 'Administration'},
    {value: 'POSCLRIC', label: 'Clerical'},
    {value: 'POSDTDNTY', label: 'Data Entry'},
    {value: 'POSREC', label: 'Recruitment'},
    {value: 'POSTRN', label: 'Training'}
];

const warehousing = [
    {value: 'POSALLGEN', label: 'All General'},
    {value: 'POSDEVAN', label: 'Container Devanning'},
    {value: 'POSGENWRH', label: 'General Warehousing'},
    {value: 'POSINV', label: 'Inventory'},
    {value: 'POSMPI', label: 'MPI Accredited'},
    {value: 'POSOPRTN', label: 'Operations'},
    {value: 'POSPKPK', label: 'Pick/Packer'},
    {value: 'POSRFSNR', label: 'RF Scanner'},
    {value: 'POSLIFT15', label: 'Heavy Lifting 15kg'},
    {value: 'POSLIFT25', label: 'Heavy Lifting 25kg'},
];

const qualifications = [
    {value: 'CERTBDEG', label: 'Bachelors Degree'},
    {value: 'CERTMDEG', label: 'Masters Degree'}
];

export const skills = [
    {label:"Licences", options: [
        {label:"Licence Classes", options : licenceClasses.sort(compare)},
        {label:"Licence Endorsements", options: licenceEndorsements.sort(compare)},
        {label:"Licence Skills & Experience", options: licenseSkillsExperience.sort(compare)},
    ]},
    {label:"Skills/Positions", options: [
        {label:"Administration", options: administration.sort(compare)},
        {label:"Warehousing", options: warehousing.sort(compare)}
    ]},
    {label: "Certificates and Qualifications", options: qualifications.sort(compare)}
];

function compare( a, b ) {
    if ( a.label < b.label ){
      return -1;
    }
    if ( a.label > b.label ){
      return 1;
    }
    return 0;
  }

export const skillLabel = (skillCode, skills) => {
    

    for (let index = 0; index < skills.length; index++) {
        const element = skills[index];
        
        if (element.value === skillCode) return element.label;
        if (element.options) {
            const result = skillLabel(skillCode, element.options);
            if (result) return result;
        }
    }
    
    return null;
    
}
