import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SubmissionDoneMessage = (props) => {
    return <>
    <div
      className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 bg-gray-700 overflow-hidden flex flex-col items-center justify-center"
    >
      {/* <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div> */}
        <FontAwesomeIcon icon={faCheck} size="4x" className="text-brand-500 mb-4" />
      <h2 className="text-center text-white text-xl font-semibold">
        Thanks!
      </h2>
      <p className="w-1/3 text-center text-white">
        Your submission has been received.
      </p>
    </div>
  </>
}

export default SubmissionDoneMessage;